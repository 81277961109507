<template>
  <div class="containerSideBar">
    <h2 class="sideTitle fw600">Historique facturation</h2>
    <h2 class="sideIcon fw600">
      <!-- <img src="../assets/icons/files.svg" alt="Historique facturation" />
       -->
      Historique
    </h2>

    <div
      v-for="(document, index) in documents"
      :key="index"
      class="line"
      :class="{ selectedLine: selected_document_id === document.id }"
      @click="selectDocument(document)"
    >
      <div class="topLine">
        <div
          v-if="document.is_payment_action_enabled"
          class="statusPoint"
          :class="getStatus(document)"
          v-tooltip.right="getTooltip(document)"
        ></div>
        <div v-else class="statusPoint statusSmall bgDarkGrey"></div>

        <p class="priceMenu">
          {{ formatMoney(document.amount) }}
          <span v-if="windowWidth > 630"> {{ document.locale_currency }} </span>
        </p>
      </div>

      <span class="grey date">{{
        transformDate(document.payment_deadline_at)
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Side_bar",
  props: {},
  data() {
    return {
      windowWidth: window.innerWidth,
      is_payment_action_enabled: false,
    };
  },
  computed: {
    ...mapState(["documents", "selected_document_id", "selected_document"]),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  created() {},
  watch: {
    selected_document: {
      handler(newVal) {
        if (newVal == null) return;

        this.is_payment_action_enabled = newVal.is_payment_action_enabled;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getStatus(document) {
      if (document.amount == "0.00") return "bgGreen";

      switch (document.status) {
        case "Paid":
          return "bgGreen";
        case "Refunded":
          return "bgDarkGrey";
        case "Pending":
          return "bgOrange";
        case "Not paid":
          return "bgRed";
        default:
          return "bgRed";
      }
    },
    getTooltip(document) {
      if (document.is_payment_action_enabled == false) return "";
      if (document.amount == "0.00") return "Payé";

      switch (document.status) {
        case "Paid":
          return "Payé";
        case "Refunded":
          return "Remboursé";
        case "Pending":
          return "En attente";
        case "Not paid":
          return "En retard";
        default:
          return "En retard";
      }
    },
    selectDocument(document) {
      this.$store.dispatch("setSelectedDocumentId", {
        selected_document_id: document.id,
      });

      this.$store.dispatch("setSelectedDocument", {
        selected_document: document,
      });
    },
    transformDate(stringDate) {
      let newDate = new Date(stringDate);

      let day =
        newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();

      let mounth =
        newDate.getMonth() + 1 < 10
          ? "0" + (newDate.getMonth() + 1)
          : newDate.getMonth() + 1;

      return day + "/" + mounth + "/" + newDate.getFullYear();
    },
    formatMoney(amount) {
      return new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 2,
      }).format(amount);
    },
  },
};
</script>

<style>
.containerSideBar {
  width: fit-content;
  height: calc(100vh - 40px);
  padding: 40px 0px;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: auto;
  width: 225px;
}

.sideTitle,
.sideIcon {
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  padding: 0px 20px;
}
.sideTitle {
  display: block;
}
.sideIcon {
  display: none;
}

.statusPoint {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.selectedLine {
  background-color: #ebeff2;
  border-radius: 5px;
}

.line {
  cursor: pointer;
  padding: 8px 24px;
  margin: 0 20px;
  margin-top: 10px;
}

.line:hover {
  background-color: #ebeff2;
  border-radius: 5px;
}

.topLine {
  display: flex;
  align-items: center;
}

.priceMenu {
  font-size: 13px;
  font-weight: normal;
}

.bgRed {
  background-color: #e3465e;
}

.statusSmall {
  margin-right: 5px;
}

@media screen and (max-width: 1400px) {
  .line {
    padding: 8px 12px;
  }

  .priceMenu,
  .date {
    font-size: 12px;
  }
}

/* -- Mobile --*/
@media screen and (max-width: 630px) {
  .containerExport {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .containerButton {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .containerSideBar {
    width: 140px;
  }

  .sideTitle {
    display: none;
  }

  .sideIcon {
    display: block;
    text-align: center;
    /* width: 120px; */
  }

  .sideIcon img {
    width: 24px;
    height: 24px;
  }

  .line {
    padding: 2.66px 8px;
    margin: 0 5px;
  }
}
</style>
