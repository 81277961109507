const mutations = {
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_LOGO(state, payload) {
    state.logo = payload;
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  CHANGE_STEP(state, payload) {
    state.step_connect = payload;
  },
  SET_APP(state, payload) {
    state.appId = payload;
  },
  SET_APP_NAME(state, payload) {
    state.appName = payload;
  },
  SET_DOCUMENT(state, payload) {
    state.document = payload;
  },
  SET_EMAILS(state, payload) {
    state.emails = payload;
  },
  SET_NATIONAL_ID(state, payload) {
    state.nationalId = payload;
  },
  SET_DOCUMENTS(state, payload) {
    state.documents = payload;
  },
  SET_SELECTED_DOCUMENT_ID(state, payload) {
    state.selected_document_id = payload;
  },
  SET_SELECTED_DOCUMENT(state, payload) {
    state.selected_document = payload;
  },
  SET_SUPPLIER_TOKEN(state, payload) {
    state.supplierToken = payload;
  },
  SET_BEARER(state, payload) {
    state.bearer = payload;
  },
  SET_DOMAIN(state, payload) {
    state.domain = payload;
  },
  SET_ACCOUNT_MANAGER(state, payload) {
    state.account_manager = payload;
  },
  SET_WEBFLOW(state, payload) {
    state.webflow = payload;
  },
};

export default mutations;
