<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" v-if="showBankInfo">
        <header class="modal-header">
          <div class="containerHeader bgBlue">
            <h1 class="titleModal white">Effectuer un virement</h1>
            <i @click="close" class="fa-solid fa-xmark fa-xl closeButton"></i>
          </div>
        </header>

        <div class="containerBody">
          <div v-if="this.bank_details.name != null">
            <table style="width: 100%">
              <tr>
                <td class="black">Numéro de facture</td>
                <td class="containerBankInfo">
                  <input
                    type="text"
                    :value="document.number"
                    class="inputBankInfo black"
                    readonly
                  />
                  <div @click="activeButton('number')" class="copyButton2">
                    <img src="../../assets/icons/copy.svg" alt="copy element" />
                    <span
                      class="tooltiptext tooltip-left"
                      :class="{
                        tooltiptextClicked:
                          buttons_activated.buttonNumber === true,
                      }"
                      >Copié</span
                    >
                  </div>
                </td>
              </tr>

              <tr>
                <td class="black">Montant ({{ document.locale_currency }})</td>
                <td class="containerBankInfo">
                  <input
                    type="text"
                    :value="formatMoney(document.amount)"
                    class="inputBankInfo black"
                    readonly
                  />
                  <div @click="activeButton('amount')" class="copyButton2">
                    <img src="../../assets/icons/copy.svg" alt="copy element" />
                    <span
                      class="tooltiptext tooltip-left"
                      :class="{
                        tooltiptextClicked:
                          buttons_activated.buttonAmount === true,
                      }"
                      >Copié</span
                    >
                  </div>
                </td>
              </tr>

              <tr>
                <td class="black">Nom destinataire</td>
                <td class="containerBankInfo">
                  <input
                    type="text"
                    :value="bank_details.name"
                    class="inputBankInfo black"
                    readonly
                  />
                  <div @click="activeButton('name')" class="copyButton2">
                    <img src="../../assets/icons/copy.svg" alt="copy element" />
                    <span
                      class="tooltiptext tooltip-left"
                      :class="{
                        tooltiptextClicked:
                          buttons_activated.buttonName === true,
                      }"
                      >Copié</span
                    >
                  </div>
                </td>
              </tr>

              <tr>
                <td class="black">IBAN</td>
                <td class="containerBankInfo">
                  <input
                    type="text"
                    :value="bank_details.iban"
                    class="inputBankInfo black"
                    readonly
                  />
                  <div @click="activeButton('iban')" class="copyButton2">
                    <img src="../../assets/icons/copy.svg" alt="copy element" />
                    <span
                      class="tooltiptext tooltip-left"
                      :class="{
                        tooltiptextClicked:
                          buttons_activated.buttonIban === true,
                      }"
                      >Copié</span
                    >
                  </div>
                </td>
              </tr>

              <tr>
                <td class="black">BIC</td>
                <td class="containerBankInfo">
                  <input
                    type="text"
                    :value="bank_details.bic"
                    class="inputBankInfo black"
                    readonly
                  />
                  <div @click="activeButton('bic')" class="copyButton2">
                    <img src="../../assets/icons/copy.svg" alt="copy element" />
                    <span
                      class="tooltiptext tooltip-left"
                      :class="{
                        tooltiptextClicked:
                          buttons_activated.buttonBic === true,
                      }"
                      >Copié</span
                    >
                  </div>
                </td>
              </tr>
            </table>

            <div class="containerInfoSupp">
              Copiez ces informations dans votre système de virement bancaire
              pour régler la facture.
            </div>
          </div>

          <div v-if="client_id">
            <hr
              v-if="this.bank_details.name != null"
              style="margin-top: 15px; margin-bottom: 15px"
            />

            <h2 class="titlePaypal">
              Payer par carte bancaire via le bouton Paypal
            </h2>

            <div class="button txtButton mauto" style="height: 25px">
              <div id="paypal-container"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from "vue";

export default {
  name: "Modal_BankInfo",
  props: ["bank_details", "document", "client_id", "token"],
  data() {
    return {
      showBankInfo: true,
      showPaiment: false,
      windowWidth: window.innerWidth,
      buttons_activated: {
        buttonNumber: false,
        buttonAmount: false,
        buttonName: false,
        buttonIban: false,
        buttonBic: false,
      },
      currency: "EUR", // Devise
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    this.currency = this.document.locale_currency;

    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=" +
      this.client_id +
      "&currency=" +
      this.currency +
      "&disable-funding=paylater";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      this.initPaypal();
    });
  },
  methods: {
    close() {
      this.$emit("close");

      if (this.showPaiment) {
        document.getElementById("paypal-container").innerHTML = "";
      }
    },
    activeButton(button) {
      const buttonProp = `button${button.charAt(0).toUpperCase()}${button.slice(
        1
      )}`;
      this.buttons_activated[buttonProp] = true;

      switch (button) {
        case "amount":
          navigator.clipboard.writeText(this.document.amount);
          break;
        case "number":
          navigator.clipboard.writeText(this.document.number);
          break;
        default:
          navigator.clipboard.writeText(this.bank_details[button]);
          break;
      }

      setTimeout(() => {
        this.buttons_activated[buttonProp] = false;
      }, 1500);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    initPaypal() {
      // eslint-disable-next-line no-undef
      paypal
        .Buttons({
          style: {
            layout: "horizontal",
            color: "silver",
            shape: "rect",
            label: "paypal",
            tagline: false,
          },
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: this.document.locale_currency,
                    value: this.document.amount,
                  },
                },
              ],
            });
          },
          onApprove: (data, actions) => {
            return actions.order
              .capture()
              .then((details) => {
                if (details.status == "COMPLETED") {
                  Vue.$toast.open({
                    message: "Votre paiement a été effectué avec succès !",
                    type: "success",
                  });
                } else if (details.status == "PENDING") {
                  Vue.$toast.open({
                    message: "Votre paiement est en attente !",
                    type: "info",
                  });
                } else {
                  Vue.$toast.open({
                    message: "Une erreur est survenue lors du paiement !",
                    type: "error",
                  });
                }

                this.close();
              })
              .catch(function (error) {
                console.log("Payment failed: " + error);
              });
          },
        })
        .render("#paypal-container");
    },
    formatMoney(amount) {
      return new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 2,
      }).format(amount);
    },
  },
};
</script>

<style>
.containerBankInfo {
  display: flex;
  justify-content: end;
  padding-bottom: 5px;

  position: relative;
}

.inputBankInfo {
  width: 330px;
  height: 26px;
  padding-left: 10px;
  padding-right: 35px;
  /* border-radius: 5px 0px 0px 5px; */
  border-radius: 5px;
  border: 1px solid #003b5175;
}

.button {
  width: fit-content;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  cursor: pointer;
}

.copyButton {
  border-radius: 0px 5px 5px 0px;
}

.copyButton:hover {
  background-color: #369e3b;
}

.copyButton2 {
  position: absolute;
  top: 50%;
  transform: translate(-10px, -50%);
  cursor: pointer;
}

.copyButton2 > img {
  width: 15px;
  height: 15px;
}

.containerInfoSupp {
  color: #99a5b5;
  font-size: 13px;
  margin: 15px 0px;
  padding-right: 25px;
  text-align: center;
  margin-bottom: 0px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

td {
  font-size: 14px;
}

.titlePaypal {
  margin-bottom: 15px;
  font-size: 16px;
  color: #003b51;
  text-align: center;
}

.paypal-button {
  max-width: 250px !important;
  margin: 0 auto !important;
}
</style>
