<template>
  <div class="content">
    <div
      class="containerExport mt40"
      v-if="!loading"
      @click.self="
        isModalPaiementVisible = false;
        isModalConnectVisible = false;
        isModalImportVisible = false;
      "
    >
      <table class="recapInformation">
        <tr>
          <td class="title green">
            {{ formatMoney(document.amount) }} {{ document.locale_currency }}
          </td>
        </tr>
        <tr>
          <td class="title black">
            {{ document_type }} de {{ document.organization_name }}
          </td>
        </tr>
        <tr
          v-if="
            isPaymentActionEnabled &&
            ['pending', 'not paid'].includes(document.status.toLowerCase()) &&
            parseInt(document.amount) > 0
          "
        >
          <td style="padding-top: 9px">
            <span class="pd710 bgGrey greyLight radius5 fw500 ls">
              À régler avant le
              {{ transformDate(document.payment_deadline_at) }}
            </span>
          </td>
        </tr>
      </table>

      <div class="containerButton mt30">
        <div
          v-if="
            isPaymentActionEnabled &&
            ['pending', 'not paid'].includes(document.status.toLowerCase()) &&
            parseInt(document.amount) > 0
          "
        >
          <div class="bank-button">
            <div
              @click="showModalPaiement"
              class="containerButtonInfo bgWhite radius5 txtButton"
              style="box-shadow: 0px 5px 8px 0px rgba(126, 128, 131, 0.11)"
            >
              <div
                v-if="bank_details && client_id == null"
                class="black ph20 pv7 fw500 ls"
              >
                Régler par virement bancaire
              </div>

              <div
                v-if="client_id && bank_details == null"
                class="black ph20 pv7 fw500 ls"
              >
                Régler par CB sécurisé
              </div>

              <div
                v-if="client_id && bank_details"
                class="black ph20 pv7 fw500 ls"
              >
                Régler par CB sécurisé ou virement bancaire
              </div>
            </div>
          </div>
        </div>

        <div v-if="supplier_app_id != appId" class="import-button">
          <span
            id="ou"
            class="pd20H pd10Top black fw300"
            v-if="
              isPaymentActionEnabled &&
              ['pending', 'not paid'].includes(document.status.toLowerCase()) &&
              parseInt(document.amount) > 0
            "
          >
            ET
          </span>

          <div
            v-if="bearer != null && bearer.length > 0"
            style="padding-bottom: 40px"
          >
            <div
              class="bgGreen ph20 pv7 radius5 txtButton fw500 ls"
              style="
                box-shadow: 0px 4px 10px 0px rgba(46, 212, 122, 0.16);
                cursor: pointer;
              "
              v-on:click="showModalConnect()"
            >
              Importer dans {{ name }}
            </div>
          </div>

          <div v-else id="importButton" class="containerButtonInfo">
            <div
              class="bgGreen ph20 pv7 radius5 txtButton fw500 ls"
              style="box-shadow: 0px 4px 10px 0px rgba(46, 212, 122, 0.16)"
            >
              Importer en comptabilité
            </div>
          </div>

          <div
            class="dropDownBanner"
            :class="{
              cardCenterButton: domain !== 'sinao.app',
              alignLeft:
                isPaymentActionEnabled === false && domain === 'sinao.app',
              cardCenter:
                isPaymentActionEnabled === true && domain === 'sinao.app',
            }"
          >
            <div
              v-if="bearer == null || bearer.length == 0"
              class="card bgWhite"
            >
              <div class="cardHeader">
                <h1 class="cardTitle black">Fichier .FEC</h1>
              </div>

              <div>
                <span class="black">
                  Téléchargez le fichier des écritures comptables pour
                  l’importer dans votre logiciel de comptabilité.
                </span>
              </div>

              <div>
                <div
                  class="cardButton button bgBlue txtButton"
                  style="margin-top: 48px"
                  @click="downloadFecDocument"
                >
                  <span class="white fw600"> Télécharger (.FEC) </span>
                </div>
              </div>
            </div>

            <div
              v-if="domain == 'sinao.app'"
              class="card bgGreen"
              style="width: calc(315px + 80px)"
            >
              <div class="cardHeader">
                <img class="cardLogo" src="../assets/logo-white.svg" alt="" />
                <!-- <span class="white pd3Left"> connect </span> -->
                <span class="link pd3Left white">Link</span>
              </div>

              <div>
                <h2 class="titleModal white" style="margin-top: 20px">
                  Automatisez votre comptabilité
                </h2>
                <div
                  class="fw300"
                  style="color: #f5f6f8; padding-top: 10px; font-size: 13px"
                >
                  Entre utilisateurs {{ name }}, vous pouvez automatiser vos
                  échanges de factures en quelques clics.<br />
                  <br />
                  {{ name }} est un logiciel de comptabilité et facturation
                  électronique simple et ludique pour entrepreneurs et
                  dirigeants.
                </div>
              </div>

              <div>
                <div
                  class="cardButton button bgWhite txtButton"
                  style="max-width: 220px"
                >
                  <span class="green" style="font-weight: 600; font-size: 13px">
                    <a
                      v-if="bearer == null || bearer.length == 0"
                      class="redirectButton green"
                      :href="
                        domain === 'sinao.app'
                          ? 'https://www.sinao.fr/fonctionnalites/gestion-des-depenses'
                          : ''
                      "
                      :target="domain == 'sinao.app' ? '_blank' : ''"
                    >
                      Découvrir {{ name }}
                    </a>
                  </span>
                </div>
                <div
                  v-if="bearer.length == 0"
                  v-on:click="showModalConnect()"
                  class="fw300"
                  style="
                    color: #f5f6f8;
                    padding-top: 7px;
                    font-size: 13px;
                    cursor: pointer;
                  "
                >
                  Déjà un compte ?
                  <b style="font-weight: 500"> Se connecter </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="containerDocument">
        <div class="containerDownload" @click="downloadDocument">
          <i class="fas fa-save"></i>
          <span class="black pf10 fw600">Télécharger la facture (PDF)</span>
        </div>

        <img :src="'data:image/png;base64,' + document.preview" />
      </div>

      <ModalPaiement
        v-show="isModalPaiementVisible"
        @close="closeModalPaiement"
        @keydown.esc="closeModalPaiement"
        :bank_details="bank_details"
        :document="document"
        :client_id="client_id"
        :token="token"
      />

      <ModalConnect
        v-show="isModalConnectVisible"
        @close="closeModalConnect"
        @keydown.esc="closeModalConnect"
        :document="document"
      />
    </div>
  </div>
</template>

<script>
import ModalConnect from "../components/Modals/ModalConnect.vue";
import ModalPaiement from "../components/Modals/ModalPaiement.vue";

import { mapState } from "vuex";
import axios from "axios";
import Vue from "vue";
import { query } from "sinao-corejs";

export default {
  name: "Export_document",
  props: {},
  data() {
    return {
      loading: true,
      url: "https://api.sinao.app/v1",
      token: "",
      bank_details: {},
      document: {},
      client_id: "",
      supplier_app_id: null,
      document_type: "",
      isModalPaiementVisible: false,
      isModalImportVisible: false,
      isModalConnectVisible: false,
      isPaymentActionEnabled: false,
    };
  },
  components: {
    ModalConnect,
    ModalPaiement,
  },
  filters: {
    decodeBase64(value) {
      return atob(value);
    },
  },
  watch: {
    selected_document: {
      handler(newVal) {
        if (newVal == null) return;

        this.document = newVal;
        this.isPaymentActionEnabled = newVal.is_payment_action_enabled;
        this.bank_details = newVal.bank_details;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState([
      "appId",
      "bearer",
      "selected_document",
      "domain",
      "name",
      "webflow",
    ]),
  },
  async created() {
    this.loading = true;

    const cookieArray = document.cookie.split("; ");
    for (const cookie of cookieArray) {
      const [name, value] = cookie.split("=");
      if (name == "token" || name == "ken") {
        this.$store.dispatch("setBearer", { bearer: value });
        break;
      }
    }

    this.url =
      window.location.hostname === "localhost"
        ? "https://api.sinao.test/v1"
        : "https://api.sinao.app/v1";

    let urlParams = new URLSearchParams(window.location.search);
    this.isModalConnectVisible = urlParams.get("supplier_relation")
      ? true
      : false;
    let supplierToken = urlParams.get("token");
    if (supplierToken != null && supplierToken.length > 0) {
      this.$store.dispatch("setSupplierToken", { token: supplierToken });
    }

    this.token = urlParams.get("token");
    if (this.token == null || this.token == "") {
      // window.location.href = this.webflow;
      window.location.href = "https://sinao.fr";
    }

    await query({
      operationId: "app.documents.sales.exportinvoice.token_validation",
      params: {
        token: this.token,
      },
    })
      .then((response) => {
        this.$store.dispatch("setNationalId", {
          nationalId: response.client_national_id,
        });

        this.$store.dispatch("setDocuments", {
          documents: response.documents,
        });

        this.$store.dispatch("setSelectedDocumentId", {
          selected_document_id: response.document_selected,
        });

        this.$store.dispatch("setSelectedDocument", {
          selected_document: response.documents.find(
            (document) => document.id == response.document_selected
          ),
        });

        this.supplier_app_id = response.supplier_app_id;
        document.title =
          "Vos factures de " +
          response.document.organization_name +
          " | " +
          this.name +
          " Link";
        this.document = response.document;
        this.$store.dispatch("setDocument", { document: response.document });
        this.$store.dispatch("setEmails", { emails: response.emails });
        this.client_id = response.client_id;
        this.document_type = response.document_type;
        // this.isPaymentActionEnabled = response.is_payment_action_enabled;
        this.loading = false;
      })
      .catch(() => {
        // window.location.href = this.webflow;
        window.location.href = "https://sinao.fr";
      });

    if (this.isModalConnectVisible) {
      this.showModalConnect();
    }
  },
  methods: {
    transformDate(stringDate) {
      let newDate = new Date(stringDate);

      let day =
        newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();

      let mounth =
        newDate.getMonth() + 1 < 10
          ? "0" + (newDate.getMonth() + 1)
          : newDate.getMonth() + 1;

      return day + "/" + mounth + "/" + newDate.getFullYear();
    },
    showModalPaiement() {
      if (
        this.document.amount > 0 &&
        (this.bank_details.name != null || this.client_id != "")
      ) {
        this.isModalPaiementVisible = true;
      } else {
        if (this.document.amount > 0) {
          Vue.$toast.open({
            message: "Impossible de rélger une facture de 0€",
            type: "error",
          });
        } else {
          Vue.$toast.open({
            message:
              "Impossible de rélger la facture, votre fournisseur n'a pas renseigné ses coordonnées de paiement",
            type: "error",
          });
        }
      }
    },
    closeModalPaiement() {
      if (this.isModalPaiementVisible) {
        this.isModalPaiementVisible = false;
      }
    },
    showModalImport() {
      this.isModalImportVisible = true;
    },
    closeModalImport() {
      this.isModalImportVisible = false;
    },
    async downloadDocument() {
      var token = this.document.token;
      if (this.document.number == null && token == null) {
        token = this.token;
      }

      const a = document.createElement("a");
      const hrefUrl = `${this.url}/invoice/download/pdf/${token}`;
      a.href = hrefUrl;
      a.setAttribute("download", `${this.document.id}.pdf`);
      a.click();
    },
    async downloadFecDocument() {
      // const token = this.token;
      const token = this.document.token;
      axios
        .get(`${this.url}/invoice/download/fec/${token}`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "invoice.zip");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async showModalConnect() {
      this.isModalConnectVisible = true;
    },
    closeModalConnect() {
      if (this.isModalConnectVisible) {
        this.isModalConnectVisible = false;
      }
    },
    formatMoney(amount) {
      return new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 2,
      }).format(amount);
    },
  },
};
</script>

<style>
.content {
  width: 100%;
  height: calc(100vh - 40px);
  overflow-y: auto;
  padding-bottom: 40px;

  border-radius: 0 10px 0 0;
  background-color: #f5f6f8;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 999;
  transform: none;
  height: fit-content !important;
}

.containerExport {
  /* width: 50%; */
  width: 100%;
  padding-left: 25%;
  padding-right: 25%;
  margin: auto;
  overflow-y: auto;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 109.02%;
}

.txtButton {
  font-size: 13px;
  font-weight: 400; /* SEMI-BOLD */
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  text-decoration: none;
}

.link:link,
.link:visited {
  color: #369e3b;
}

.containerButton {
  display: flex;
  justify-content: left;
  width: fit-content;

  position: relative;
}

.containerButtonInfo {
  text-align: center;
  cursor: pointer;
}

.containerDownload {
  display: flex;
  align-content: center;
  justify-content: end;
  margin-bottom: 10px;
  cursor: pointer;
  width: fit-content;
  margin-left: auto;
}

.containerDocument img {
  width: 100%;
  margin: auto;
}

.dropDownBanner {
  position: absolute;
  top: 35px; /* 50px */
  right: calc(-280px + 176px / 2);
  display: none;
  border-radius: 5px;
}

#importButton:hover + .dropDownBanner,
.dropDownBanner:hover {
  display: flex !important;
  box-shadow: 0px 2px 2px rgba(50, 45, 40, 0.1),
    0px 6px 15px rgba(54, 47, 40, 0.25);
}

.card {
  padding: 25px 40px;
  width: calc(280px);
}

.card + .bgGreen {
  width: 390px !important; /* 400px !important; */
  max-width: 100%;
}

.card:first-child {
  border-radius: 5px 0 0 5px;
}
.card:last-child {
  border-radius: 0 5px 5px 0;
}

.ph20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pv7 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.cardHeader {
  display: flex;
  justify-content: left;
  align-items: end;
  margin-bottom: 20px;
}

.cardTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  font-style: normal;
  letter-spacing: 0.01em;
  margin: 0px;
}

.cardLogo {
  width: 73px;
  height: 24px;
}

.cardButton {
  margin-top: 20px;
  /* width: calc(100% - 30px); */
  width: 100%;
  height: 32px;
  border-radius: 5px;
  text-decoration: none;
}

.infoButton {
  max-width: 180px;
  display: block;
  padding-top: 5px;
}

.ls {
  letter-spacing: 0.13px;
}

.cardCenter {
  right: calc(-196px * 1.5);
}

.cardCenterButton {
  right: calc(-196px / 4) !important;
}
.cardCenterButton .card {
  border-radius: 5px !important;
}

.alignLeft {
  left: calc(-170px / 2) !important;
  right: auto !important;
}

@media screen and (max-width: 1800px) {
  .containerExport {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media screen and (max-width: 1100px) {
  .dropDownBanner {
    left: calc((15% + 475px / 2 - 350px) / 2);
    right: auto !important;
  }
}

@media screen and (max-width: 1000px) {
  .dropDownBanner {
    left: calc((100% - 640px) / 2);
    right: auto !important;
  }
}

@media screen and (max-width: 910px) {
  .dropDownBanner {
    flex-wrap: wrap-reverse;
    left: calc(350px / 2) !important;
    right: calc((15% - 180px / 2 - 125px) / 2) !important;
    top: 55px !important;
    width: 350px !important;
  }

  .alignLeft {
    flex-wrap: wrap-reverse;
    max-width: 350px;
    left: calc((-350px + 213px) / 2) !important;
    right: -350px !important;
    top: 35px !important;
  }

  .alignLeft .card,
  .dropDownBanner .card {
    width: 100% !important;
    max-width: 350px;
  }

  .alignLeft .card:first-child,
  .dropDownBanner .card:first-child {
    border-radius: 0px 0px 5px 5px !important;
  }

  .alignLeft .card:last-child,
  .dropDownBanner .card:last-child {
    border-radius: 5px 5px 0px 0px !important;
  }
}

@media screen and (max-width: 800px) {
  .containerButton {
    position: initial;
  }

  .dropDownBanner {
    top: 28vh !important;
    left: calc(350px / 2) !important;
    right: calc((15% - 180px / 2 - 125px) / 2) !important;
    width: 350px !important;
  }

  .alignLeft {
    top: 23vh !important;
    /* left: calc((15% - 196px / 2) / 2) !important; */
    left: calc((196px - 350px / 2.5) / 2) !important;
    right: -350px !important;
  }

  .card {
    text-align: left !important;
  }
}

@media screen and (max-width: 700px) {
  .cardCenter {
    left: calc((100% - 520px) / 2) !important;
    right: auto !important;
    top: 28vh !important;
    width: 350px !important;
  }
}

/* -- Mobile --*/
@media screen and (max-width: 630px) {
  .containerExport {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .containerButton {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .cardCenter {
    left: calc((100% - 480px) / 2) !important;
    right: auto !important;
    top: 28vh !important;
    width: 350px !important;
  }

  .alignLeft {
    left: calc((100% - 196px / 2 - 350px) / 2) !important;
    right: auto !important;
  }
}

@media screen and (max-width: 600px) {
  #importButton:hover + .dropDownBanner,
  .dropDownBanner:hover {
    /* display: block !important; */
  }

  .card:first-child {
    border-radius: 5px 5px 0px 0px !important;
  }

  .card:last-child {
    border-radius: 0px 0px 5px 5px !important;
  }

  .dropDownBanner {
    /* right: 10px; */
    /* width: 280px; */
  }

  .cardButton {
    margin-top: 20px !important;
  }

  .card {
    padding: 26px 30px;
  }

  span.black.pf10:nth-child(1) {
    display: none;
  }

  .alignLeft {
    left: calc((100% - 120px - 350px) / 2) !important;
    right: auto !important;
  }
}

@media screen and (max-width: 592px) {
  .dropDownBanner {
    /* top: 32vh; */
  }
}

@media screen and (max-width: 500px) {
  .dropDownBanner {
    /* top: 32vh; */
    /* right: 10px; */
    width: 280px !important;
  }

  .cardCenter {
    left: calc((100% - 280px - 110px) / 2) !important;
    right: auto !important;
  }

  .alignLeft {
    left: calc((100% - 110px - 280px) / 2) !important;
    right: 280px !important;
  }
}

@media screen and (max-width: 430px) {
  .title {
    font-size: 22px;
  }

  .recapInformation {
    margin: auto;
  }

  .recapInformation > tr {
    text-align: center;
  }

  .containerButton {
    display: grid;
  }

  .containerButton:nth-child(2) {
    margin-bottom: 0px !important;
  }

  #ou {
    display: none;
  }

  .containerButtonInfo {
    margin-bottom: 20px;
  }

  .mb40 {
    margin-bottom: 40px !important;
  }

  .pb40 {
    padding-bottom: 40px !important;
  }

  .txtButton {
    font-size: 13px;
  }

  span {
    font-size: 10px;
  }

  .titlePaypal,
  .titleModal {
    font-size: 14px;
  }

  .dropDownBanner {
    top: 30vh !important;
    left: calc((100% - 275px - 110px) / 2) !important;
    right: auto !important;
  }

  .alignLeft {
    top: 21vh !important;
    left: calc((100% - 275px - 110px) / 2) !important;
    right: auto !important;
  }

  .card {
    padding: 35px 40px;
  }
}
</style>
