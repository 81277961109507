var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[(!_vm.loading)?_c('div',{staticClass:"containerExport mt40",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.isModalPaiementVisible = false;
      _vm.isModalConnectVisible = false;
      _vm.isModalImportVisible = false;}}},[_c('table',{staticClass:"recapInformation"},[_c('tr',[_c('td',{staticClass:"title green"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.document.amount))+" "+_vm._s(_vm.document.locale_currency)+" ")])]),_c('tr',[_c('td',{staticClass:"title black"},[_vm._v(" "+_vm._s(_vm.document_type)+" de "+_vm._s(_vm.document.organization_name)+" ")])]),(
          _vm.isPaymentActionEnabled &&
          ['pending', 'not paid'].includes(_vm.document.status.toLowerCase()) &&
          parseInt(_vm.document.amount) > 0
        )?_c('tr',[_c('td',{staticStyle:{"padding-top":"9px"}},[_c('span',{staticClass:"pd710 bgGrey greyLight radius5 fw500 ls"},[_vm._v(" À régler avant le "+_vm._s(_vm.transformDate(_vm.document.payment_deadline_at))+" ")])])]):_vm._e()]),_c('div',{staticClass:"containerButton mt30"},[(
          _vm.isPaymentActionEnabled &&
          ['pending', 'not paid'].includes(_vm.document.status.toLowerCase()) &&
          parseInt(_vm.document.amount) > 0
        )?_c('div',[_c('div',{staticClass:"bank-button"},[_c('div',{staticClass:"containerButtonInfo bgWhite radius5 txtButton",staticStyle:{"box-shadow":"0px 5px 8px 0px rgba(126, 128, 131, 0.11)"},on:{"click":_vm.showModalPaiement}},[(_vm.bank_details && _vm.client_id == null)?_c('div',{staticClass:"black ph20 pv7 fw500 ls"},[_vm._v(" Régler par virement bancaire ")]):_vm._e(),(_vm.client_id && _vm.bank_details == null)?_c('div',{staticClass:"black ph20 pv7 fw500 ls"},[_vm._v(" Régler par CB sécurisé ")]):_vm._e(),(_vm.client_id && _vm.bank_details)?_c('div',{staticClass:"black ph20 pv7 fw500 ls"},[_vm._v(" Régler par CB sécurisé ou virement bancaire ")]):_vm._e()])])]):_vm._e(),(_vm.supplier_app_id != _vm.appId)?_c('div',{staticClass:"import-button"},[(
            _vm.isPaymentActionEnabled &&
            ['pending', 'not paid'].includes(_vm.document.status.toLowerCase()) &&
            parseInt(_vm.document.amount) > 0
          )?_c('span',{staticClass:"pd20H pd10Top black fw300",attrs:{"id":"ou"}},[_vm._v(" ET ")]):_vm._e(),(_vm.bearer != null && _vm.bearer.length > 0)?_c('div',{staticStyle:{"padding-bottom":"40px"}},[_c('div',{staticClass:"bgGreen ph20 pv7 radius5 txtButton fw500 ls",staticStyle:{"box-shadow":"0px 4px 10px 0px rgba(46, 212, 122, 0.16)","cursor":"pointer"},on:{"click":function($event){return _vm.showModalConnect()}}},[_vm._v(" Importer dans "+_vm._s(_vm.name)+" ")])]):_c('div',{staticClass:"containerButtonInfo",attrs:{"id":"importButton"}},[_c('div',{staticClass:"bgGreen ph20 pv7 radius5 txtButton fw500 ls",staticStyle:{"box-shadow":"0px 4px 10px 0px rgba(46, 212, 122, 0.16)"}},[_vm._v(" Importer en comptabilité ")])]),_c('div',{staticClass:"dropDownBanner",class:{
            cardCenterButton: _vm.domain !== 'sinao.app',
            alignLeft:
              _vm.isPaymentActionEnabled === false && _vm.domain === 'sinao.app',
            cardCenter:
              _vm.isPaymentActionEnabled === true && _vm.domain === 'sinao.app',
          }},[(_vm.bearer == null || _vm.bearer.length == 0)?_c('div',{staticClass:"card bgWhite"},[_vm._m(0),_vm._m(1),_c('div',[_c('div',{staticClass:"cardButton button bgBlue txtButton",staticStyle:{"margin-top":"48px"},on:{"click":_vm.downloadFecDocument}},[_c('span',{staticClass:"white fw600"},[_vm._v(" Télécharger (.FEC) ")])])])]):_vm._e(),(_vm.domain == 'sinao.app')?_c('div',{staticClass:"card bgGreen",staticStyle:{"width":"calc(315px + 80px)"}},[_vm._m(2),_c('div',[_c('h2',{staticClass:"titleModal white",staticStyle:{"margin-top":"20px"}},[_vm._v(" Automatisez votre comptabilité ")]),_c('div',{staticClass:"fw300",staticStyle:{"color":"#f5f6f8","padding-top":"10px","font-size":"13px"}},[_vm._v(" Entre utilisateurs "+_vm._s(_vm.name)+", vous pouvez automatiser vos échanges de factures en quelques clics."),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.name)+" est un logiciel de comptabilité et facturation électronique simple et ludique pour entrepreneurs et dirigeants. ")])]),_c('div',[_c('div',{staticClass:"cardButton button bgWhite txtButton",staticStyle:{"max-width":"220px"}},[_c('span',{staticClass:"green",staticStyle:{"font-weight":"600","font-size":"13px"}},[(_vm.bearer == null || _vm.bearer.length == 0)?_c('a',{staticClass:"redirectButton green",attrs:{"href":_vm.domain === 'sinao.app'
                        ? 'https://www.sinao.fr/fonctionnalites/gestion-des-depenses'
                        : '',"target":_vm.domain == 'sinao.app' ? '_blank' : ''}},[_vm._v(" Découvrir "+_vm._s(_vm.name)+" ")]):_vm._e()])]),(_vm.bearer.length == 0)?_c('div',{staticClass:"fw300",staticStyle:{"color":"#f5f6f8","padding-top":"7px","font-size":"13px","cursor":"pointer"},on:{"click":function($event){return _vm.showModalConnect()}}},[_vm._v(" Déjà un compte ? "),_c('b',{staticStyle:{"font-weight":"500"}},[_vm._v(" Se connecter ")])]):_vm._e()])]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"containerDocument"},[_c('div',{staticClass:"containerDownload",on:{"click":_vm.downloadDocument}},[_c('i',{staticClass:"fas fa-save"}),_c('span',{staticClass:"black pf10 fw600"},[_vm._v("Télécharger la facture (PDF)")])]),_c('img',{attrs:{"src":'data:image/png;base64,' + _vm.document.preview}})]),_c('ModalPaiement',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalPaiementVisible),expression:"isModalPaiementVisible"}],attrs:{"bank_details":_vm.bank_details,"document":_vm.document,"client_id":_vm.client_id,"token":_vm.token},on:{"close":_vm.closeModalPaiement,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeModalPaiement.apply(null, arguments)}}}),_c('ModalConnect',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalConnectVisible),expression:"isModalConnectVisible"}],attrs:{"document":_vm.document},on:{"close":_vm.closeModalConnect,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeModalConnect.apply(null, arguments)}}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardHeader"},[_c('h1',{staticClass:"cardTitle black"},[_vm._v("Fichier .FEC")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"black"},[_vm._v(" Téléchargez le fichier des écritures comptables pour l’importer dans votre logiciel de comptabilité. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardHeader"},[_c('img',{staticClass:"cardLogo",attrs:{"src":require("../assets/logo-white.svg"),"alt":""}}),_c('span',{staticClass:"link pd3Left white"},[_vm._v("Link")])])
}]

export { render, staticRenderFns }